import request from '../common/request';

// 出件列表
export const getMatterhandlePage = (params) => request({
  url: '/api/blade-out/matterhandle/front/page',
  method: 'get',
  params:{
    ...params,
    descs: "create_time"
  }
});