<template>
  <div class="query">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell-group style="margin-bottom:10px" v-for="item in list" :key="item.id">
          <van-cell :border="false" icon="clock-o" title="时间：" :value="item.createTime" />
          <van-cell :border="false" icon="label-o" title="事项名：" :value="item.serviceName" />
          <van-cell :border="false" icon="idcard" title="证件类型：" :value="item.handleCertTypeName" />
          <van-cell :border="false" icon="idcard" title="出件方式" :value="item.outTypeName" />
          <van-cell v-if="[1,2].includes(item.outType)" :border="false" icon="location-o" title="领取地点：" value="青原区行政申请服务中心" />
          <van-cell v-if="[3].includes(item.outType)" :border="false" icon="location-o" title="快递单号：" :value="item.out.expressNumber" />
          <van-cell v-if="[1].includes(item.outType)" :border="false" icon="desktop-o" title="领取窗口：" :value="item.out.windowNo" />
          <van-cell :border="false" icon="aim" title="状态：" :value="item.statusName" />
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getMatterhandlePage } from "@/api/out";
import { List, PullRefresh,Cell, CellGroup } from "vant";
export default {
  name: "query",
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
  },
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      list:[],
      page: {
        current: 0,
        size: 10,
      },
    };
  },
  mounted(){
    this.onLoad();
  },
  methods: {
    onRefresh() {
      this.finished = false;
      this.page.current = 0;
      this.list = [];
      this.onLoad();
    },
    onLoad() {
      this.page.current++;
      this.getList();
    },
    async getList() {
      const res = await getMatterhandlePage({
        current: this.page.current,
        size: this.page.size,
      });
      // console.log(res)
      this.refreshing = false;
      this.loading = false;
      if (!res.data.records.length) {
        this.finished = true;
      }
      this.list = this.list.concat(res.data.records);
    }
  }
};
</script>

<style scoped lang="scss">
.query {
  padding: 10px 16px;
}
.van-cell__title, .van-cell__value {
  flex: auto !important;
}
</style>